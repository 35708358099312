<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">إضافة شخص مفقود </span>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="form">
              <v-row
                ><v-col cols="12" class="ma-0 pa-0">
                  <v-file-input
                    accept="image/png, image/jpeg, image/bmp"
                    label="اضف صورة الشخص للتمكن من التعرف عليه بشكل افضل "
                    outlined
                    dense
                    :multiple="false"
                    v-model="data.photo"
                  ></v-file-input
                ></v-col>
                <v-col cols="12" sm="6" md="6" class="ma-0 pa-0">
                  <v-text-field
                    outlined
                    :disabled="update == true ? true : false"
                    class="ma-1 pa-0"
                    v-model="data.first_name"
                    :label="$t('product.newPopup.name')"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="ma-0 pa-0">
                  <v-text-field
                    outlined
                    :disabled="update == true ? true : false"
                    class="ma-1 pa-0"
                    v-model="data.last_name"
                    label="الكنية"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="ma-0 pa-0">
                  <v-text-field
                    outlined
                    class="ma-1"
                    v-model="data.birthday"
                    :rules="[rules.required]"
                    label="تاريخ الميلاد"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="ma-0 pa-0">
                  <v-text-field
                    outlined
                    class="ma-1"
                    v-model="data.number"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    :rules="[rules.required]"
                    label="رقم هاتف للتواصل"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="ma-0 pa-0"> </v-col>

                <v-col cols="12" class="ma-0 pa-0">
                  <v-textarea
                    outlined
                    class="ma-1 pa-0"
                    classname="form-control"
                    :rules="[rules.required]"
                    persistent-hint
                    label="اضف وصف / معلومات اكثر ان وجد"
                    v-model="data.info"
                  >
                  </v-textarea>
                </v-col> </v-row
            ></v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="close">
            {{ $t("btn.cancel") }}
          </v-btn>
          <!-- -->
          <v-btn color="primary" :disabled="clicked"
            :loading="clicked"  :dark="!clicked" @click.prevent="submit">
            {{ $t("btn.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import cl from "@/services/api.js";

export default {
  data: () => {
    return {
      valid: true,
      clicked: false,
      rules: {
        required: null,
      },
    };
  },
  created() {
    this.rules.required = (v) => !!v || this.$t("rules.require");
  },
  props: {
    dialog: { type: Boolean, default: () => false },
    data: { type: Object, default: () => {} },
    update: { type: Boolean, default: () => true },
  },

  computed: {
    title() {
      return this.update == true
        ? "product.newPopup.update"
        : "product.newPopup.title";
    },
  },
  methods: {
    close() {
      if (this.update == false) this.$refs.form.reset();
      this.$emit("close-dialog", false);
    },
    submit() {
      this.$refs.form.validate();
      if (this.valid == false) return;
      this.clicked = true;
      const payload = new FormData();

      payload.append("photo", this.data.photo);
      payload.append("first_name", this.data.first_name);
      payload.append("last_name", this.data.last_name);
      payload.append("number", this.data.number);
      payload.append("birthday", this.data.birthday);
      payload.append("info", this.data.info);

      cl.addOne(payload)
        .then((res) => {
          this.$refs.form.reset();
          this.$store.dispatch(
            "api/handleServiceException",
            { e: res },
            { root: true }
          );

          this.$emit("close-dialog", false);
          this.clicked = false;
        })
        .catch((err) => {
          this.clicked = false;

          this.$store.dispatch(
            "api/handleServiceException",
            { e: err },
            { root: true }
          );

          this.$emit("close-dialog", false);
        });
    },
  },
};
</script>
