import Request from "../handleRequest/Request";

function toQueryStrings(params) {
  return "?" + Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&')
}
export default {
  async list(filter) {

    return await Request.get("api/persons", toQueryStrings(filter))
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },

  addOne(data) {
    return Request.post("api/persons", data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },

};
