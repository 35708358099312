<template>
  <v-skeleton-loader
    class="mx-auto"
    type="card, article, actions"
  ></v-skeleton-loader>
</template>

<script>
export default {
  name: "LoadCard",
};
</script>
