import axios from "axios";
export default class Request {
  static async get(url, queryParams) {
    return new Promise(async (resolve, reject) => {
      let headers = await this.getHeaders();
      axios
        .get(url + queryParams, { headers: headers })
        .then((res) => {
          resolve(res);
        })
        .catch(function (error) {
          console.warn(error)
          reject(error.response);
          // throw error.response;
        });
    });
  }

  static async post(url, data) {
    return new Promise((resolve, reject) => {
      let headers = this.getHeaders();
      axios
        .post(url, data, { headers: headers })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.warn(error)
          reject(error.response);
        });
    });
  }
  static async uploadFile(url, data) {
    return new Promise((resolve, reject) => {
      let headers = {
        "content-type": "multipart/form-data",
      };
      if (localStorage.getItem("token") != null)
        headers["authorization"] = localStorage.getItem("token");
      axios
        .post(url, data, {
          headers: headers,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.warn(error)
          reject(error.response);
        });
    });
  }

  static async put(url, data) {
    return new Promise((resolve, reject) => {
      let headers = this.getHeaders();
      axios
        .put(url, data, { headers: headers })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.warn(error)
          reject(error.response);
        });
    });
  }
  static async patch(url, data) {
    return new Promise((resolve, reject) => {
      let headers = this.getHeaders();
      axios
        .patch(url, data, { headers: headers })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.warn(error)
          reject(error.response);
        });
    });
  }
  static async delete(url, queryParams) {
    return new Promise((resolve, reject) => {
      let headers = this.getHeaders();
      axios
        .delete(url + queryParams, { headers: headers })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.warn(error)
          reject(error.response);
        });
    });
  }
  static getHeaders() {
    let header = {
      accept: "application/json",
      "content-type": "application/json",
    };
    if (localStorage.getItem("token") != null)
      header["authorization"] = localStorage.getItem("token");
    return header;
  }
}
