<template>
  <v-container
    :style="$vuetify.theme.dark ? '' : 'background: #eeeeee'"
    style="min-height: 110vh"
  >
    <v-row align="center" justify="center" class="table-cell">
      <v-col cols="12" md="9">
        <v-card class="text-center py-6">
          <v-text-field
            outlined
            @keydown="serachCall"
            v-model="serach"
            color="primary"
            class="ma-1"
            label="بحث في قاعدة البيانات"
          ></v-text-field>
          <h3 class="gray-text ma-3">إضافة بيانات جديدة</h3>
          <P class="gray-text"
            >يمكنك اضافة شخص يتم البحث عنه او معتقل او مفقود خلال سنوات الثورة
            السورية ليتم المساعدة في ايجاده , لقد بنيت هذه الصفحة لتسهيل عمليات
            البحث عن المفقودين وهي مجانية ولايوجد اي رسوم .</P
          >

          <v-chip color="primary" @click="openDialog()" label outlined
            >إضافة مفقود
          </v-chip>
          <v-divider class="ma-1"></v-divider>

          <v-chip class="mr-1" color="black" label outlined>
            العدد الكلي المتوفر لدينا حتى الآن : {{ total }}
            <v-icon>mdi-account-group</v-icon>
          </v-chip>
          <v-chip class="mr-1" icon="mdi-delete" color="red" label outlined>
            عدد الموتى : غير محدد بعد <v-icon>mdi-account-group</v-icon>
          </v-chip>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" class="table-cell">
      <v-col cols="12" v-if="loading"><LoadCard /></v-col>
      <v-col cols="12" md="7" class="pa-0" v-else-if="personList.length > 0">
        <v-card v-for="item in personList" v-bind:key="item.id" class="ma-6">
          <v-row>
            <v-col cols="12" md="4">
              <v-avatar class="ma-3" size="125" tile>
                <v-img
                  v-if="item.photo == null"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRF-ExnKXwTybHkZFPV8PAniae32NIh-AgiA&s"
                ></v-img>

                <v-img v-else :src="item.photo"></v-img> </v-avatar
            ></v-col>
            <v-col cols="12" md="8">
              <v-card-title class="text-h5">
                {{ item.first_name }} {{ item.last_name }}
              </v-card-title>
              <v-card-subtitle> رقم للتواصل {{ item.number }} </v-card-subtitle>
              <v-card-subtitle>
                تاريخ الميلاد {{ item.birthday }} - <br />{{ item.info }}
              </v-card-subtitle>
              <!-- <v-card-actions>
                <v-btn class="ml-2 mt-5" outlined rounded small>
                  START RADIO
                </v-btn>
              </v-card-actions> -->
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <Popup :dialog="dialog" :update="false" :data="{}" @close-dialog="close" />
  </v-container>
</template>

<script>
import LoadCard from "@/views/components/LoadCard.vue";
import Popup from "./Popup.vue";
import cl from "@/services/api.js";

export default {
  name: "products-page",
  components: {
    Popup,
    LoadCard,
  },
  data() {
    return {
      clicked: false,
      serach: "",
      param: null,
      total: 0,
      dialog: false,
      loading: true,
      personList: [],
    };
  },
  computed: {},
  methods: {
    openDialog() {
      this.dialog = true;
    },
    close(val) {
      this.dialog = val;
      const filter = {};
      cl.list(filter).then((res) => {
        this.personList = res.data.data;
        this.loading = false;
      });
    },
    serachCall(event) {
      if (event.keyCode === 13) {
        this.loading = true;
        const filter = { q: this.serach };
        cl.list(filter).then((res) => {
          this.personList = res.data.data;
          this.loading = false;
        });
      }
    },
  },
  created() {
    this.param = this.$router.history.current.params;
  },
  mounted() {
    const filter = {};
    cl.list(filter).then((res) => {
      this.personList = res.data.data;
      this.total = this.personList.length;
      this.loading = false;
    });
  },
};
</script>
